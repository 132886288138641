export type User = {
  id: string;
  email: string;
  industries: string[];
  locations: string[];
  first_name: string;
  profileImage: string;
  last_name: string;
  role: UserRoles;
  stripe_customer_id: string;
  company: Company;
  company_id: string;
  app_view_enabled?: boolean;
  sub_name: string;
  pdf_ask_enabled?: boolean;
  cancel_at_period_end: boolean;
  cancel_at: Date;
  current_period_end: Date;
  has_usage_based_billing: boolean;
  has_session_types_enabled: boolean;
};

export type LLMModel = {
  id: string;
  name: string;
  desc: string;
  provider: string;
  api_key?: string;
  model_id?: string;
};

export type InterviewInvitationInfo = {
  recruiterName: string;
  stageDuration: number;
  companyName: string;
  roomId: string;
  applicationId: string;
  sessionTitle: string;
};

export type NotificationButton = {
  data: any;
  name: string;
  type: 'accept' | 'decline';
};

export type Notification = {
  id: string;
  email: string;
  title: string;
  description: string;
  notificationType: string;
  buttons: NotificationButton[];
  lastName: string;
  role: UserRoles;
  companyId: string;
};

export enum UserRoles {
  COMPANY_ADMIN = 'company_admin',
  RECRUITER = 'recruiter',
  INDIVIDUAL = 'individual',
}

export type Company = {
  _id: string;
  email: string;
  name: string;
  culture: string;
  industries: string[];
  locations: string[];
  country: string;
  description: string;
  brand_color: string;
  website_link: string;
  owner_id: string;
  profile_image: string;
  board_domain: string;
  account_type: string;
  available_interviews: number;
  sub_name: string;
  sub_id: string;
};

export type InviteInfo = {
  urlToken: string;
  company: Company;
  invitedEmail: string;
};

export type SessionPhase = {
  phase: string;
  label: string;
  percentage: number;
  instruction: string;
};

export type MindConfig = {
  questions: { text: string }[];
  scenarios: { text: string }[];
  intro: string;
  voice: string;
  voice_provider: 'elevenlabs' | 'google';
  language: string;
  notes: string;
  model: LLMModel;
  conversation_type: string;
  is_push_to_talk_disabled: boolean;
  enable_text_input: boolean;
  session_duration?: number;
  enable_recording?: boolean;
  session_phases?: SessionPhase[];
  allow_interruption?: boolean;
};

export type Template = {
  _id: string;
  id: string;
  company_id: string;
  public_id: string;
  location?: string;
  plain_text: {
    jd: string;
  };
  session_type: string;
  visibility: 'open' | 'closed' | 'draft';
  name: string;
  jd: string;
  owner_id: string;
  last_published_date?: string;
  mind_config: MindConfig;
  job_type: string;
};

export type Vacancy = {
  id: string;
  _id: string;
  name: string;
  job_type: string;
  jd: IJd;
  last_published_date: string;
  mind_config: MindConfig;
  public_id: string;
  company_id: string;
  created_at_timestamp: number;
  company_info: ICompanyInfo;
};
export type IJd = {
  type: string;
  content: any[];
};

export type ICompanyInfo = {
  name: string;
  profileImage: string;
  accountType: string;
  publicId: string;
};

export type Application = {
  first_name: string;
  id: string;
  last_name: string;
  template_id: string;
  created_at_timestamp: Date;
  updated_at_timestamp: Date;
  email: string;
  file_url: string;
  status: string;
  room_id?: string;
  summary_id?: string;
  has_comments?: boolean;
};

export type ApplicationWithTemplate = {
  template: IDocument<Template>;
  application: IDocument<Application>;
};

export type IDocument<T> = T & { id: string };

export type Driver = {
  id: string;
};

export type Stage = {
  _id: string;
  name: string;
  enabled: boolean;
  duration: number;
};

export type RoomTemplate = {
  ownerId: string;
  drivers: Driver[];
  name: string;
  stages: Stage[];
};

export type GetAppsResponse = {
  listings: Application[];
  total_pages: number;
  duplicate_emails: string[];
};

export type InterviewResult = {
  _id: string;
  company_id: string;
  job_title: string;
  totalApplications: number;
  avg_match_rate: number | null;
  avg_session_duration: number | null;
  completed_interviews: number;
  completion_rate: number | null;
  company_name: string;
  company_profile_image: string;
};

export type RoomInvitation = {
  state: string;
  url_token: string;
  room_public_id: string;
  application_id: string | null;
  invited_email: string;
  created_at: string;
};
export type Room = {
  id: string;
  company_id: string;
  session_type: string;
  public_id: string;
  state: string;
  event: any;
  share_id: string;
  application_id: string;
  scheduled_at: string;
  started_at: number;
  company_name: string;
  session_duration: number;
  company_profile_image: string;
};
export type ChecklistItem = {
  id: string;
  isChecked: boolean;
  text: string;
};

export type CompanionState = {
  isSpaceBarPressed: boolean;
  isMuted: boolean;
  stop: () => void;
  start: () => void;
  requestLeave: () => void;
};

export type SummaryResult = {
  short_summary: string;
  abilities: any[];
  keyPoints: any[];
  detailness: number;
  dialogue_depth: number;
  english_level: string;
  examples: number;
  questions: any[];
  relevance: number;
  response_time_rate: number;
  custom_questions?: {
    answer_to_question: string;
    question_index: number;
    points_earned: number;
    max_points: number;
  }[];
  sum_of_points: number;
  recommendations: string[];
  match_rate: number;
};

export type CustomQuestion = {
  answer_to_question: string;
  question_index: number;
  points_earned: number;
  max_points: number;
};

export enum SessionType {
  COMPANY_JOB_INTERVIEW = 'company-job-interview',
  GENERAL = 'general',
  PRESENTATION = 'presentation',
}

export type Recommendations = {
  status: string;
  sorted_candidate_emotions: {
    emotion: string;
    emotionColor: string;
  }[];
  good_parts: string[];
  recommendations: string[];
};

export type Prosody = {
  data: {
    duration: number;
    visualization: string;
    bucketedSeries: {
      [key: string]: {
        points: {
          time: number;
          value: number;
        }[];
      };
    };
    topSeries: string[];
    sortedEmotions: string[];
    newBucketedSeries: any;
  };
  status: string;
};

export interface AssessmentBlock {
  _id: string;
  title: string;
  description: string;
  instructions: string;
  result_type: 'percentage' | 'number' | 'string';
  company_id: string;
}

export interface AssessmentTemplate {
  _id: string;
  name: string;
  block_ids: string[];
  company_id: string;
}
